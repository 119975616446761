<template>
  <div class="relative h-screen w-screen">
    <NuxtPicture
      src="/auth-background.jpg"
      :img-attrs="{class: 'object-cover size-full'}"
      class="fixed size-full"
    />
    <div class="fixed bottom-16 left-16 flex w-80 flex-col gap-8">
      <NuxtImg
        width="155"
        src="/censes-logo-white.svg"
      />
      <p class="text-2xl text-white">
        Bruggen bouwen tussen mbo en bedrijfsleven
      </p>
    </div>
    <main class="fixed right-0 top-0 h-screen w-screen max-w-3xl bg-white">
      <div class="size-full justify-stretch px-32 pb-12 pt-32">
        <slot />
      </div>
    </main>
  </div>
</template>
